import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('../layout'),
    redirect:'/index',
    children:[
      {
        path: '/index',
        name: '首页',
        component: () => import('../views/index/index')
      },
      {
        path: '/userCenter',
        name: '个人中心',
        component: () => import('../views/userCenter/index')
      },
      {
        path: '/sys/user',
        name: '用户管理',
        component: () => import('../views/sys/user/index')
      },
      {
        path: '/sys/role',
        name: '角色管理',
        component: () => import('../views/sys/role/index')
      },
      {
        path: '/sys/menu',
        name: '菜单管理',
        component: () => import('../views/sys/menu/index')
      },
      {
        path: '/bsns/department',
        name: '部门管理',
        component: () => import('../views/bsns/Department')
      },
      {
        path: '/bsns/post',
        name: '岗位管理',
        component: () => import('../views/bsns/Post')
      },
      {
        path: '/bsns/order',
        name: '订单管理',
        component: () => import('../views/bsns/order/index')
      },
      {
        path: '/bsns/worker',
        name: '维修员管理',
        component: () => import('../views/bsns/worker/index')
      },
      {
        path: '/bsns/user',
        name: '客户管理',
        component: () => import('../views/bsns/user/index')
      },
      {
        path: '/bsns/enterprise',
        name: '企业管理',
        component: () => import('../views/bsns/enterprise/index')
      },
      {
        path: '/bsns/swiper',
        name: '轮播管理',
        component: () => import('../views/bsns/swiper/index')
      },
      {
        path: '/bsns/board',
        name: '数据看板',
        component: () => import('../views/bsns/board/index')
      }
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
